.lc_ct {
  width: var(--max-width);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .lc_box {
    width: 88%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // padding: 5vw;
  }
}
