.p_h {
  // border: 1px solid red;
  padding: var(--sm-sp);
  position: relative;

  .p_h_c {
    display: flex;
    position: relative;
    gap: var(--sm-sp);
    align-items: center;

    .ret_btn {
      // background-color: #e3e3e3;
      border: none;
    }
    .s_c {
      // border: 1px solid #e3e3e3;
      flex: 1;
      position: relative;
      height: 2rem;
      .tit {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        font-weight: 700;
      }
      // .tit_lf {
      //   // border: 1px solid #E3E3E3;
      //   position: absolute;
      //   top: 50%;
      //   left: 4rem;
      //   transform: translate(-50%, -50%);
      // }
    }
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  // z-index: 1;
  // position: relative;
}
