.rec_cont {
  padding: var(--sm-sp);
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: var(--sm-col-space);

  .tit_v {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tv{
      gap: var(--sm-col-space);
      display: flex;
      align-items: center;
    }
    .tit {
      font-size: 2rem;
    }
    .label-text {
      margin-right: var(--sm-sp);
    }
  }
  .m_cont{
    padding: 0;
  }

  .input {
    width: 98%;
    margin: 0 auto;
  }
}


