.header_v {
  // color: var(--fallback-bc, oklch(var(--bc) / var(--tw-text-opacity)));
  // color: oklch(var(--btn-color, var(--b2)) / var(--tw-bg-opacity));
  // 背景白
  color: var(--fallback-b1, oklch(var(--b1) / 1));
  // 暗黑字体色
  // color: var(--fallback-bc, oklch(var(--bc) / 1));

  // padding: 0 2vw;
  padding: var(--xl-pad) var(--sm-sp);
  margin-bottom: 2vw;

  .hv_it {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .btn {
      position: absolute;
      right: 0;
      background-color: rgba($color: #000000, $alpha: 0);
      border: none;
      color: var(--fallback-b1, oklch(var(--b1) / 1));
      box-shadow: none;
    }
    .btn-circle {
      width: auto;
      height: auto;
    }
  }

  .info_it {
    display: flex;
    flex-direction: column;
    gap: 3vw;
    margin-top: 8vw;

    .it_col {
      display: flex;
      flex-direction: column;
    }
    .op_t {
      font-size: 1.8rem;
    }
    .it_row {
      display: flex;
      gap: 3vw;
      font-size: 0.8rem;
    }
  }
}

.drawer {
  max-width: var(--max-width);
  overflow: hidden;



  user-select: none; /* 标准语法 */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .drawer-side {
    .uif_v {
      display: flex;
      gap: var(--xl-pad);
    }
    //
    .menu {
      background-color: oklch(var(--b2));
      .avatar {
        margin-bottom: var(--sm-col-space);
      }
    }
  }
}

.tb_v {
  width: 80%;
  // border: #000000 1px solid;
  margin: 0 auto;
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3); /* 半透明背景 */
  backdrop-filter: blur(10px); /* 高斯模糊 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  .active {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3); /* 半透明背景 */
    backdrop-filter: blur(10px); /* 高斯模糊 */
  }
}
