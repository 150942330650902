.t_btn {
  position: absolute;
  right: 0;
}
.menu_v {
  padding: var(--sm-sp) 0 0 0;
  gap: var(--sm-sp);
  width: 100%;
  .m_it {
    // --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    //   var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    // border-radius: var(--rounded-btn, 0.5rem);
  }
  .wallet_a {
    width: 120px;
  }
}
