/** @format */

.p_v {
  padding: var(--sm-sp);
  display: flex;
  flex-direction: column;
  gap: var(--sm-col-space);

  .opt_v {
    display: flex;
    flex-direction: row-reverse;
    gap: var(--md-pad);
  }
  .form_v {
    display: flex;
    flex-direction: column;
    gap: var(--sm-col-space);
    .name {
      flex: 1;
    }
    .input {
      width: 40%;
    }
  }
}
