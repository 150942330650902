// /* 全局修改 modal 样式 */
// .modal {
//   .mb-rerow {
//     display: flex;
//     flex-direction: row-reverse;
//     gap: 1rem;
//   }
// }
.uatd_v {
  display: flex;
  flex-direction: column;
  gap: var(--sm-col-space);
  margin-top: var(--sm-col-space);
}
