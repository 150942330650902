.rl_v {
  display: flex;
  flex-direction: column;
  padding: var(--sm-sp);
  padding-bottom: 150px;
  //   gap: var(--sm-col-space);
  .rv_i {
    height: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.1px solid var(--fallback-bc, oklch(var(--bc) / 0.1));
    .rv_i_r {
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      align-items: flex-end;

      .tim{
        font-size: 0.6rem;
        color: oklch(var(--n));
      }
    }
  }
  .loan {
    color: oklch(var(--er));
  }
  .col_out {
    color: oklch(var(--su));
  }
  .col_put {
    color: oklch(var(--wa));
  }
}
